import React from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import {Breadcrumb, Layout, Seo, Share} from '@app/components';

import logo from '@app/assets/images/logo.png';

const AboutHair: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.AboutUs',
  };
  const {t} = useLocale();

  return (
    <Layout>
      <Seo title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div className="container mx-auto px-4 pt-20 pb-5 max-w-3xl lg:px-0 xl:max-w-3xl text-lg">
        <img className="m-auto" src={logo} width={200} />

        <h1 className="mt-20 mb-5 font-bold text-center">About Hair</h1>

        <div className="who-am-i-description">
          <p className="mb-4 m-auto text-center mb-20">
            Hair follicle and graft are frequently confused terms. Hair follicle
            embedded in the fat tissue in the skull, surrounded by hair is a
            structure that contains the base of the hair surrounded by producing
            cells.
          </p>

          <h2 className="mt-20 mb-5 font-bold text-center">
            What are the stages of hair growth at the root?
          </h2>

          <p className="mb-4 m-auto text-center mb-5">
            Hair Growth Stages:Growth of the hair shaft in the hair follicle is
            a process in constant transformation. Each hair growth phase
            continues in the hair follicle, so each hair follicle goes through
            different phases is found.
          </p>

          <p className="mb-4 m-auto text-center mb-5">
            <b>1-Anogen Phase:</b> This phase is the growth phase. Hair
            follicles Approximately 85% are in this phase. The anogen phase
            lasts approximately 2-6 years. is a process between. Saç telleri bu
            süreçte yılda yaklaşık 10 cm can be prolonged.
          </p>

          <p className="mb-4 m-auto text-center mb-5">
            <b>2-Catogen Phase:</b> This process is a transition phase. Growth
            process After the hair follicles pass through the hair follicles in
            a period of 1-3 weeks is the process of follicle regression. During
            this process, the dermal papilla regresses and deteriorates.
            Approximately 1-2% of hair follicles are in this process.
          </p>

          <p className="mb-4 m-auto text-center mb-5">
            <b>3-Telogenous Phase:</b> This is the resting phase of the hair
            follicles and is approximately is a process of 2-3 months. During
            this period, the dermal papilla remains intact and attached to the
            hair follicle. Approximately 13% of all hair phase. When this
            process is over, the anogen phase starts again. Dermal Create
            reconnection of papillae and hair follicle and stimulate hair
            production restarts. At this stage, new hairs replace the lost hair.
            it starts to grow.
          </p>

          <p className="mb-4 m-auto text-center mb-20">
            A graft is a structure extracted from the scalp containing hair
            follicles. Each graft contains at least one and at most 4-5 hair
            follicles. are available. The grafts are taken from the donor area
            and transplanted for transplantation. Usually, before the
            transplantation procedure, the doctor how many grafts the patient
            needs in transplantation treatment determines the number of grafts.
            The number of grafts differs for each person. In hair
            transplantation treatment method, the grafts are usually between two
            ears. from the donor site. Grafts come in different sizes, shapes
            and root may differ in terms of their number.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default AboutHair;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
